import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../App.css';
import Loader from '../../components/Loader';
import Stepper from '../../components/stepper';

export default function TyroKiosk() {

  const [iclient, setIclient] = useState();
  const [libraryReady, setLibraryReady] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [statusMessage, setStatusMessage] = useState('');
  const [retryCount, setRetryCount] = useState(0);
  const [question, setQuestion] = useState(null);
  const [isSignatureRequired, setIsSignatureRequired] = useState(false);

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let amount = params.get('Amount');
  let buttonBackgroundColor = params.get('buttonBackgroundColor');
  let mId = params.get('mId');
  let tId = params.get('tId');
  let integrationKey = params.get('integrationKey');
  let secretKey = params.get('secretKey');

  const amountWithoutDecimal = Math.round(amount * 100);
  const stringifiedAmount = amountWithoutDecimal.toString();

  //Added Script to head;
  useEffect(() => {
    const script = document.createElement('script');
    if (secretKey === process.env.REACT_APP_APIKEY) {
      script.src = `${process.env.REACT_APP_TEST_TYROURL}/iclient-v1.js`;
    }
    else {
      script.src = `${process.env.REACT_APP_TYROURL}/iclient-v1.js`;
    }
    script.async = true;
    script.onload = () => setLibraryReady(true);
    document.head.appendChild(script);


    return () => {
      document.head.removeChild(script); // Cleanup the script when unmounting
    };
    // eslint-disable-next-line
  }, [])



  useEffect(() => {
    if (libraryReady) {
      const posProductInfo = {
        posProductVendor: "This Solution",
        posProductName: "This Solution POS",
        posProductVersion: "1.0.0"
      };
      /* eslint-disable no-undef */
      const newIclient = new TYRO.IClient(secretKey, posProductInfo);
      setIclient(newIclient);
    }
  }, [libraryReady])

  useEffect(() => {
    if (iclient) {
      handleApprove();
    }
    // eslint-disable-next-line
  }, [iclient])


  const handleStatusMessage = (value) => {
    setStatusMessage(value);
  }

  const handleOptionClick = async (option, answerCallback) => {
    if (option === 'QUIT') {
      const data = {
        success: false,
        message: 'Transaction Declined'
      }
      window.ReactNativeWebView.postMessage(JSON.stringify(data));
    }

    if (option === 'OK' && question.text === 'POS is not paired with a terminal.') {
      const data = {
        success: false,
        message: 'Transaction Declined'
      }
      window.ReactNativeWebView.postMessage(JSON.stringify(data));
    }

    // Call answerCallback with the selected option
    answerCallback(option);
    setQuestion(false);
  };

  const handleQuestionCallback = (questionValue, answerCallback) => {
    if (questionValue.text === 'APPROVED W/ SIGNATURE. Signature OK?') {
      answerCallback('NO');
    } else if (questionValue.text === 'Cancel this transaction?') {
      answerCallback('YES');
      setQuestion(false);
    }
    else {
      questionValue.callback = answerCallback;
      setQuestion(questionValue);
    }
  }

  const handleReceipt = (value) => {
    if (value.signatureRequired) {
      setIsSignatureRequired(true);
    }
  }



  const handleTransactionComplete = (value) => {

    let handleData;

    if (value.result === 'APPROVED') {
      handleData = {
        success: true,
        data: value
      }
    } else if (value.result === 'DECLINED' || value.result === 'REVERSED' || value.result === 'CANCELLED') {
      handleData = {
        data: value,
        canPrintReceipt: true
      }
      setRetryCount(prevState => prevState + 1);
    }
    if (handleData) {
      window.ReactNativeWebView.postMessage(JSON.stringify(handleData));
    }
  }



  const handleApprove = () => {
    setLoading(true);
    try {
      iclient.initiatePurchase({
        amount: stringifiedAmount,
        mid: mId,
        tid: tId,
        integrationKey: integrationKey,
        integratedReceipt: true,
        enableSurcharge: false
      }, {
        statusMessageCallback: handleStatusMessage,
        questionCallback: handleQuestionCallback,
        receiptCallback: handleReceipt,
        transactionCompleteCallback: handleTransactionComplete
      });
      setLoading(false);
    } catch (error) {
      toast.error(error.toString());
      setLoading(false);
    }
  }

  const handleQuit = async () => {
    const data = {
      success: false,
      message: 'Transaction Declined'
    }
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
  }


  const cancelTransaction = async () => {
    try {
      if (statusMessage === 'SYSTEM ERROR') {
        handleQuit();
      } else {
        await iclient.cancelCurrentTransaction()
      }
    } catch (error) {
      toast.error(error.toString());
    }
  }

  const RenderMainContent = () => {

    if (question) {
      return <ErrorMessage />;
    }


    return (
      <div className='flex flex-col w-screen h-screen justify-center'>
        <div className='flex flex-col h-screen '>
          <Stepper status={statusMessage} amount={amount} isSignatureRequired={isSignatureRequired} />
          {
            (statusMessage === 'REVERSED' || statusMessage === 'DECLINED' || statusMessage === 'CANCELLED') &&
            <div className='flex flex-col items-center justify-center'>
              <div className='flex mt-20'>
                <button style={{ background: '#' + buttonBackgroundColor }} onClick={
                  retryCount <= 3 ? () => handleApprove() :
                    () => alert('Maximum retry limit reached. Please try again later.')
                }
                  className={`px-32 py-4 text-4xl rounded-full cursor-pointer`}>
                  Retry
                </button>
                <button onClick={() => handleQuit()} className="px-32 py-4 mx-5 outline text-4xl rounded-full cursor-pointer" >
                  Cancel
                </button>
              </div>
            </div>
          }
        </div >
        <div className='flex flex-col items-center justify-end py-6'>
          {statusMessage !== 'APPROVED' && statusMessage !== 'DECLINED' && statusMessage !== 'REVERSED' && statusMessage !== 'CANCELLED' &&
            <button className='px-24 py-6 outline text-4xl text-red-800 rounded-full cursor-pointer outline-red-800' onClick={() => cancelTransaction()}>
              Cancel
            </button>
          }
        </div>
      </div >
    )
  }

  const ErrorMessage = () => (
    <div className="flex flex-col items-center w-screen h-screen">
      {question.text &&
        <div className="mb-8 text-5xl text-center leading-relaxed">
          {question.text}
        </div>
      }
      <div className='flex mt-8' >
        {question && question.options && question.options.map((option, index) => {
          return index === 0 ? (
            <button key={index} style={{ background: '#' + buttonBackgroundColor }}
              className="px-16 py-4 text-2xl rounded-full cursor-pointer"
              onClick={() => handleOptionClick(option, question.callback)}>
              {option}
            </button>) :
            (<button key={index}
              className="px-16 py-4 ms-16 outline text-2xl rounded-full cursor-pointer"
              onClick={() => handleOptionClick(option, question.callback)}>
              {option}
            </button>)
        })
        }
      </div>
    </div>
  );

  return (
    <>
      {
        isLoading ? (
          <div className='flex flex-col justify-center items-center w-screen h-screen fixed inset-0 bg-opacity-80 bg-white'>
            <Loader loaderColor={buttonBackgroundColor} />
          </div>
        ) :
          <RenderMainContent />
      }
    </>
  );

}