import { useEffect, useState } from 'react';
import '../../App.css';
import Loader from '../../components/Loader.js';
import KioskForm from 'components/KioskForm';

export default function PairWithTerminal() {

    const [libraryReady, setLibraryReady] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [data, setData] = useState({})

    const [buttonStyle, setButtonStyle] = useState({
        borderRadius: '5px',
        width: '100%',
        backgroundColor: '#F3C910',
        border: '0px',
        color: 'white',
        padding: '5px 15px',
        fontSize: '24px',
    });

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let buttonBorderRadius = params.get('buttonBorderRadius');
    let buttonBackgroundColor = params.get('buttonBackgroundColor');
    let mId = params.get('mId');
    let tId = params.get('tId');
    let secretKey = params.get('secretKey');

    useEffect(() => {
        const script = document.createElement('script');
        script.id = 'tyro-kiosk-library';
        if (secretKey === process.env.REACT_APP_APIKEY) {
            script.src = `${process.env.REACT_APP_TEST_TYROURL}/iclient-v1.js`;
        }
        else {
            script.src = `${process.env.REACT_APP_TYROURL}/iclient-v1.js`;
        }
        script.async = true;
        script.onload = () => {
            setLibraryReady(true);
        }

        //AddScript to head
        document.head.appendChild(script);
        if (buttonBorderRadius) {
            setButtonStyle(prevStyle => ({
                ...prevStyle,
                borderRadius: buttonBorderRadius + 'px'
            }));
        }

        // Update buttonStyle if buttonBackgroundColor exists
        if (buttonBackgroundColor) {
            setButtonStyle(prevStyle => ({
                ...prevStyle,
                backgroundColor: '#' + buttonBackgroundColor
            }));
        }

        return () => {
            document.head.removeChild(script); // Cleanup the script when unmounting
        };

    }, []);

    useEffect(() => {
        if (libraryReady) {
            doPairing();
        }
    }, [libraryReady]);

    const doPairing = async (mid, tid) => {
        const posProductInfo = {
            posProductVendor: "This Solution",
            posProductName: "This Solution POS",
            posProductVersion: "1.0.0"
        };
        /* eslint-disable no-undef */
        const iclient = new TYRO.IClient(secretKey, posProductInfo);

        let pairingCompleted = false;

        const timer = setTimeout(() => {
            if (!pairingCompleted) {
                const responseData = {
                    success: false,
                    message: 'Terminal not paired.',
                }
                setIsSubmitting(false);
                window.ReactNativeWebView.postMessage(JSON.stringify(responseData));
                alert("Pairing failure: " + JSON.stringify(responseData));
            }
        }, 60000)


        iclient.pairTerminal(mId, tId, function (response) {
            console.log("response", response)
            setData(response)
            if ("success" === response.status) {
                pairingCompleted = true;
                clearTimeout(timer);
                // alert("Pairing success: " + JSON.stringify(response));
                const responseData = {
                    success: true,
                    message: response.message,
                    data: {
                        mId: mId,
                        tId: mId,
                        integrationKey: response.integrationKey
                    }
                }
                setIsSubmitting(false);
                window.ReactNativeWebView.postMessage(JSON.stringify(responseData));
            } else if ("failure" === response.status) {
                const responseData = {
                    success: false,
                    message: response.message,
                }
                setIsSubmitting(false);
                window.ReactNativeWebView.postMessage(JSON.stringify(responseData));
            }
        });
    }

    const handleSubmit = (values) => {
        setIsSubmitting(true);
        doPairing(values.mid, values.tid);
    }

    return (
        <>
            {isLoading ?
                <div className='flex flex-col justify-center items-center w-screen h-screen fixed inset-0 bg-opacity-80 bg-white'>
                    <h2 className="text-2xl font-semibold text-gray-800 ">{data.status}</h2>
                    <h2 className="text-xl font-semibold text-gray-600 mb-6 text-center">{data.message}</h2>
                    <Loader loaderColor={buttonBackgroundColor} />
                </div>
                :
                <KioskForm
                    handleSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                    buttonStyle={buttonStyle}
                />
            }
        </>
    );

}
